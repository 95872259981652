// Importing jquery
import $ from "jquery";
// Importing bootstrap 
import 'bootstrap';
// Importing @popperjs 
import * as Popper from '@popperjs/core';
// Importing slick-carousel 
import 'slick-carousel';
// Importing Masonry
import Masonry from "masonry-layout";
//import "vanilla back to top";
import { addBackToTop } from 'vanilla-back-to-top';
// import Swiper bundle with all modules installed
import Swiper, { FreeMode } from '../../node_modules/swiper/swiper-bundle';
//import "AOS";
import AOS from 'aos';
//import "intlTelInput";
import intlTelInput from 'intl-tel-input';
//import "jquery.mask.min";
import 'jquery-mask-plugin'; 
// Import jquery-validation
import "jquery-validation";
import "../../node_modules/waypoints/lib/noframework.waypoints.min";
import { CountUp } from 'countup.js';
// Importing Header
import "./header";
// Importing custom gtm
//import "./cutom-gtm";
// Importing Default SCSS
import "../sass/style.scss";
// Importing Responsive SCSS
import "../sass/responsive.scss";

const swiper = new Swiper('.swiper', {
  speed: 1500,
  slidesPerView: 'auto',
  spaceBetween: 20,
  grabCursor: true,
  pagination: {
      el: ".swiper-pagination",
      clickable: true,
  },
  autoplay: {
    delay: 2500,
    disableOnInteraction: false,
  },
    // Responsive breakpoints
    breakpoints: {
      // when window width is >= 640px
      640: {
        slidesPerView: 1,
        spaceBetween: 20
      },
      768: {
        slidesPerView: 2,
        spaceBetween: 20
      },
      1024: {
        slidesPerView: 4,
        spaceBetween: 20
      },
    },
});

$('#accordionFlushExample .accordion-item').on('click', function () {
  var tabattr = $(this).attr('data-tab');
  $('.rsSlide').addClass('d-none')
  $('.rsSlide.' + tabattr).removeClass('d-none').hide().fadeIn()

});


$('form').each(function () {
  $(this).validate({
    // your options
    rules: {
      name: {
        required: true
      },
      email: {
        required: true,
        email: true
      },
      numonly: {
        required: true,
      },
      txtcontry: {
        required: true,
      },
      interested: "required",
      budget: "required",
      textarea: "required",
    },
    errorElement: "span",
    messages: {
      name: "Please enter your name",
      email: "Please enter valid email address",
      numonly: "Please enter your mobile number",
      txtcontry: "Please enter your country name",
      interested: "Please choose category",
      budget: "Please choose category"
    },
  });
});

window.onload = function() {
  const options = {
      suffix: '+',
     duration: 50
    };
    var countUp1 = new CountUp('satisfiedCustomer', 350, options);
    var countUp2 = new CountUp('projectComplete', 450, options);
    var countUp3 = new CountUp('launchedProducts', 25, options);
    var countUp4 = new CountUp('dailyVisits', 5000, options);
     new Waypoint({
      element: document.getElementById('waypoint'),
      handler: function(direction) {
    
        countUp1.start();
          countUp2.start();
          countUp3.start();
          countUp4.start();
    
      },
      offset: '90%'
    });
}

//resetForm

var myModalEl = document.getElementById('orderPackage')
myModalEl.addEventListener('hide.bs.modal', function (event) {
  // do something...
  //console.log('working')
  $("form").each(function(){
    $(this)[0]. reset();
    $(this).validate().resetForm();
  });

});


//exampleModalLabel

$(function() {
  
  AOS.init({
    offset: 50 
  });

  $("input[name='numonly']").on('input', function(e) {
      $(this).val($(this).val().replace(/[^0-9(+)]/g, ''));
  });

  $('.animated-btn .btn-animated').on('click', function() {
    var gettext = ($(this).parent().parent().parent().parent().find('.setValue').html());
    $(".getpackagename").html('<span class="text-dark">Package Selected: </span>' + gettext);
  });

  // grecaptcha.ready(function() {
  //   grecaptcha.execute('6LdJDoQcAAAAAInlEswaf4tAmNSz8Y0g6Obtopgb', {action: 'submit'}).then(function(token) {
  //       document.getElementById("packages").value = token;
  //       document.getElementById("tokenConsultancy").value = token;
  //       document.getElementById("mainform").value = token;
  //   });
  // });

  var current = location.pathname.split('/')[1];
  if (current === "") return;
  var menuItems = document.querySelectorAll('.navbar-nav li a');
  for (var i = 0, len = menuItems.length; i < len; i++) {
      if (menuItems[i].getAttribute("href").indexOf(current) !== -1) {
          menuItems[i].className += " active";
      }
  }
  
});

const input = document.querySelector("#phone");
intlTelInput(input, {
  initialCountry: "auto",
  separateDialCode: true,
  geoIpLookup: function (success, failure) {
    $.get("https://ipinfo.io", function () { }, "jsonp").always(function (resp) {
      var countryCode = (resp && resp.country) ? resp.country : "us";
      success(countryCode);
    });
  },
});

const input2 = document.querySelector("#phoneStatic");
intlTelInput(input2, {
  initialCountry: "auto",
  separateDialCode: true,
  geoIpLookup: function(success, failure) {
    $.get("https://ipinfo.io", function() {}, "jsonp").always(function(resp) {
      var countryCode = (resp && resp.country) ? resp.country : "us";
      success(countryCode);
    });
  },
});

addBackToTop({
  diameter: 56,
  backgroundColor: '#6405d3',
  textColor: '#fff'
});


$(".slider").slick({
  // normal options...
  autoplay: true,
  //infinite: true,
  slidesToScroll: 1,
  arrows: false,
  dots:true,
  autoplaySpeed: 4000,
  fade: true,
  fadeSpeed: 4000,
  lazyLoad: 'ondemand',
  draggable:false
});
$('.multiple-items').slick({
  infinite: true,
  slidesToShow: 3,
  slidesToScroll: 3,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }
    // You can unslick at a given breakpoint now by adding:
    // settings: "unslick"
    // instead of a settings object
  ]
});
$('.clients-area').slick({
  infinite: true,
  slidesToShow: 5,
  slidesToScroll: 5,
  autoplay: true,
  autoplaySpeed: 3000,
  cssEase: 'cubic-bezier(0.7, 0, 0.3, 1)',
  touchThreshold: 100,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }
    // You can unslick at a given breakpoint now by adding:
    // settings: "unslick"
    // instead of a settings object
  ]

});
$('.testimonial').slick({
  infinite: true,
  slidesToShow: 2,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 3000,
  dots: true,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }
    // You can unslick at a given breakpoint now by adding:
    // settings: "unslick"
    // instead of a settings object
  ]
});
$('.slider-banner').slick({
  infinite: true,
  slidesToShow: 7,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 3000,
  cssEase: 'cubic-bezier(0.7, 0, 0.3, 1)',
  touchThreshold: 100,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1
      }
    }
    // You can unslick at a given breakpoint now by adding:
    // settings: "unslick"
    // instead of a settings object
  ]

});
$('#homeslider')
        .on('beforeChange', function() {
            $('.main-title').removeClass("animate__animated animate__fadeInLeft");
            $('.paragraph').removeClass("animate__animated animate__fadeInDown");
            $('.slider-btn').removeClass("animate__animated animate__fadeInUp");
            $('.bannerSlider').removeClass("animate__animated animate__fadeInRight");
            // AOS.refreshHard(); this didn't work
            AOS.init();
            $('.main-title').addClass("animate__animated animate__fadeInLeft");
            $('.paragraph').addClass("animate__animated animate__fadeInDown");
            $('.slider-btn').addClass("animate__animated animate__fadeInUp");
            $('.bannerSlider').addClass("animate__animated animate__fadeInRight");
              // AOS.refreshHard(); this didn't work
              //AOS.init();
        });


// var tabEl = document.querySelector('button[data-bs-toggle="tab"]')
// if(tabEl)
// tabEl.addEventListener('shown.bs.tab', function (event) {
//   $('.multiple-items').slick('setPosition');
// });
// var tabEl = document.querySelector('button[data-bs-toggle="tab"]')
// if(tabEl)
// tabEl.addEventListener('hidden.bs.tab', function (event) {
//   $('.multiple-items').slick('setPosition');
// });

setInterval(function () {

  // vanilla JS
  // init with element
  var grid = document.querySelector('.masonry');
  var msnry = new Masonry(grid, {
    // options...
    "percentPosition": true
  });

});



window.addEventListener('DOMContentLoaded', function ()  {
  var firstButton = document.querySelector('#accordionFlushExample .accordion-item:first-child');
  if(firstButton)
  firstButton.classList.add('add-theme-color');
  var myCollapsible = document.getElementById('accordionFlushExample')
  if (myCollapsible)
    myCollapsible.addEventListener('hidden.bs.collapse', function () {
      // do something...
      firstButton.classList.remove('add-theme-color');
    })
  });
  // DOMContentLoaded  end

document.addEventListener("DOMContentLoaded", function () {

  window.addEventListener('scroll', function () {

    if (window.scrollY > 200) {
      document.getElementById('navbar_top').classList.add('fixed-top');
    } else {
      document.getElementById('navbar_top').classList.remove('fixed-top');
      // remove padding top from body
      document.body.style.paddingTop = '0';
    }
  });
});
// DOMContentLoaded  end

$(function () {
  ParallaxScroll.init();
});

var ParallaxScroll = {
  /* PUBLIC VARIABLES */
  showLogs: false,
  round: 1000,

  /* PUBLIC FUNCTIONS */
  init: function () {
    this._log("init");
    if (this._inited) {
      this._log("Already Inited");
      this._inited = true;
      return;
    }
    this._requestAnimationFrame = (function () {
      return window.requestAnimationFrame ||
        window.webkitRequestAnimationFrame ||
        window.mozRequestAnimationFrame ||
        window.oRequestAnimationFrame ||
        window.msRequestAnimationFrame ||
        function (/* function */ callback, /* DOMElement */ element) {
          window.setTimeout(callback, 1000 / 60);
        };
    })();
    this._onScroll(true);
  },

  /* PRIVATE VARIABLES */
  _inited: false,
  _properties: ['x', 'y', 'z', 'rotateX', 'rotateY', 'rotateZ', 'scaleX', 'scaleY', 'scaleZ', 'scale'],
  _requestAnimationFrame: null,

  /* PRIVATE FUNCTIONS */
  _log: function (message) {
    if (this.showLogs) console.log("Parallax Scroll / " + message);
  },
  _onScroll: function (noSmooth) {
    var scroll = $(document).scrollTop();
    var windowHeight = $(window).height();
    this._log("onScroll " + scroll);
    $("[data-parallax]").each($.proxy(function (index, el) {
      var $el = $(el);
      var properties = [];
      var applyProperties = false;
      var style = $el.data("style");
      if (style == undefined) {
        style = $el.attr("style") || "";
        $el.data("style", style);
      }
      var datas = [$el.data("parallax")];
      var iData;
      for (iData = 2; ; iData++) {
        if ($el.data("parallax" + iData)) {
          datas.push($el.data("parallax-" + iData));
        }
        else {
          break;
        }
      }
      var datasLength = datas.length;
      for (iData = 0; iData < datasLength; iData++) {
        var data = datas[iData];
        var scrollFrom = data["from-scroll"];
        if (scrollFrom == undefined) scrollFrom = Math.max(0, $(el).offset().top - windowHeight);
        scrollFrom = scrollFrom | 0;
        var scrollDistance = data["distance"];
        var scrollTo = data["to-scroll"];
        if (scrollDistance == undefined && scrollTo == undefined) scrollDistance = windowHeight;
        scrollDistance = Math.max(scrollDistance | 0, 1);
        var easing = data["easing"];
        var easingReturn = data["easing-return"];
        if (easing == undefined || !$.easing || !$.easing[easing]) easing = null;
        if (easingReturn == undefined || !$.easing || !$.easing[easingReturn]) easingReturn = easing;
        if (easing) {
          var totalTime = data["duration"];
          if (totalTime == undefined) totalTime = scrollDistance;
          totalTime = Math.max(totalTime | 0, 1);
          var totalTimeReturn = data["duration-return"];
          if (totalTimeReturn == undefined) totalTimeReturn = totalTime;
          scrollDistance = 1;
          var currentTime = $el.data("current-time");
          if (currentTime == undefined) currentTime = 0;
        }
        if (scrollTo == undefined) scrollTo = scrollFrom + scrollDistance;
        scrollTo = scrollTo | 0;
        var smoothness = data["smoothness"];
        if (smoothness == undefined) smoothness = 30;
        smoothness = smoothness | 0;
        if (noSmooth || smoothness == 0) smoothness = 1;
        smoothness = smoothness | 0;
        var scrollCurrent = scroll;
        scrollCurrent = Math.max(scrollCurrent, scrollFrom);
        scrollCurrent = Math.min(scrollCurrent, scrollTo);
        if (easing) {
          if ($el.data("sens") == undefined) $el.data("sens", "back");
          if (scrollCurrent > scrollFrom) {
            if ($el.data("sens") == "back") {
              currentTime = 1;
              $el.data("sens", "go");
            }
            else {
              currentTime++;
            }
          }
          if (scrollCurrent < scrollTo) {
            if ($el.data("sens") == "go") {
              currentTime = 1;
              $el.data("sens", "back");
            }
            else {
              currentTime++;
            }
          }
          if (noSmooth) currentTime = totalTime;
          $el.data("current-time", currentTime);
        }
        this._properties.map($.proxy(function (prop) {
          var defaultProp = 0;
          var to = data[prop];
          if (to == undefined) return;
          if (prop == "scale" || prop == "scaleX" || prop == "scaleY" || prop == "scaleZ") {
            defaultProp = 1;
          }
          else {
            to = to | 0;
          }
          var prev = $el.data("_" + prop);
          if (prev == undefined) prev = defaultProp;
          var next = ((to - defaultProp) * ((scrollCurrent - scrollFrom) / (scrollTo - scrollFrom))) + defaultProp;
          var val = prev + (next - prev) / smoothness;
          if (easing && currentTime > 0 && currentTime <= totalTime) {
            var from = defaultProp;
            if ($el.data("sens") == "back") {
              from = to;
              to = -to;
              easing = easingReturn;
              totalTime = totalTimeReturn;
            }
            val = $.easing[easing](null, currentTime, from, to, totalTime);
          }
          val = Math.ceil(val * this.round) / this.round;
          if (val == prev && next == to) val = to;
          if (!properties[prop]) properties[prop] = 0;
          properties[prop] += val;
          if (prev != properties[prop]) {
            $el.data("_" + prop, properties[prop]);
            applyProperties = true;
          }
        }, this));
      }
      if (applyProperties) {
        if (properties["z"] != undefined) {
          var perspective = data["perspective"];
          if (perspective == undefined) perspective = 800;
          var $parent = $el.parent();
          if (!$parent.data("style")) $parent.data("style", $parent.attr("style") || "");
          $parent.attr("style", "perspective:" + perspective + "px; -webkit-perspective:" + perspective + "px; " + $parent.data("style"));
        }
        if (properties["scaleX"] == undefined) properties["scaleX"] = 1;
        if (properties["scaleY"] == undefined) properties["scaleY"] = 1;
        if (properties["scaleZ"] == undefined) properties["scaleZ"] = 1;
        if (properties["scale"] != undefined) {
          properties["scaleX"] *= properties["scale"];
          properties["scaleY"] *= properties["scale"];
          properties["scaleZ"] *= properties["scale"];
        }
        var translate3d = "translate3d(" + (properties["x"] ? properties["x"] : 0) + "px, " + (properties["y"] ? properties["y"] : 0) + "px, " + (properties["z"] ? properties["z"] : 0) + "px)";
        var rotate3d = "rotateX(" + (properties["rotateX"] ? properties["rotateX"] : 0) + "deg) rotateY(" + (properties["rotateY"] ? properties["rotateY"] : 0) + "deg) rotateZ(" + (properties["rotateZ"] ? properties["rotateZ"] : 0) + "deg)";
        var scale3d = "scaleX(" + properties["scaleX"] + ") scaleY(" + properties["scaleY"] + ") scaleZ(" + properties["scaleZ"] + ")";
        var cssTransform = translate3d + " " + rotate3d + " " + scale3d + ";";
        this._log(cssTransform);
        $el.attr("style", "transform:" + cssTransform + " -webkit-transform:" + cssTransform + " " + style);
      }
    }, this));
    if (window.requestAnimationFrame) {
      window.requestAnimationFrame($.proxy(this._onScroll, this, false));
    }
    else {
      this._requestAnimationFrame($.proxy(this._onScroll, this, false));
    }
  }
};

